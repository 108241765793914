import React from 'react'
import '../../App.scss'

function Contact() {
    return (
        <div>
            <h1 className="contact">Contact Page</h1>
        </div>
    )
}

export default Contact