import React from 'react';
import '../../App.scss';

function Projects() {
    return (
        <div className="projects">
            <h1>Projects Page</h1>
        </div>
    )
}

export default Projects